import { useState, useEffect } from "react";
import { fetchData } from "../../../../GlobalFunctions";
import { ImSpinner } from "react-icons/im";
import axios, { AxiosError } from "axios";
import { toast } from "react-toastify";
import api from "../../../../api";

function StudentQuizzes() {
  interface Quiz {
    quiz_id: string;
    course_name: string;
    chapter_name: string;
    correct_answers: string;
    total_questions: string;
    score: string;
    grade_name: string;
    student_name: string;
    student_id: string;
  }

  const [loading, setLoading] = useState(true);
  const [quizzesData, setQuizzesData] = useState<Quiz[]>([]);
  const [refresh, setRefresh] = useState(0);

  const retakeQuiz = async (
    e: React.MouseEvent<HTMLButtonElement>,
    quizId: string,
    studentId: string
  ) => {
    e.preventDefault();

    try {
      const formData = new FormData();
      formData.append("quiz_id", quizId);
      formData.append("user_id", studentId);

      await api.post(`${process.env.REACT_APP_RETAKE_QUIZ}`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      setRefresh((prevRefresh: any) => prevRefresh + 1);

      toast.success("Quiz has been reset successfully!", {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        theme: "dark",
      });
    } catch (error) {
      if (axios.isAxiosError(error)) {
        const axiosError = error as AxiosError<{ message: string }>;
        const errorMessage =
          axiosError.response?.data?.message || axiosError.message;

        toast.error(
          `An error occurred while resetting the Quiz: ${errorMessage}`,
          {
            position: "top-center",
            autoClose: 2000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: false,
            theme: "dark",
          }
        );
      } else {
        toast.error("An unexpected error occurred while resetting the Quiz", {
          position: "top-center",
          autoClose: 2000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          theme: "dark",
        });
      }
      console.error("Error resetting the Quiz:", error);
    }
  };

  useEffect(() => {
    fetchData(
      `${process.env.REACT_APP_API}${process.env.REACT_APP_QUIZZES_TEACHER}`,
      (data: any) => {
        const allQuizzes = data.flatMap((student: any) =>
          student.quizzes.map((quiz: Quiz) => ({
            ...quiz,
            student_name: student.student_name,
            student_id: student.student_id,
          }))
        );
        setQuizzesData(allQuizzes);
        setLoading(false);
      }
    );
  }, [refresh]);

  if (loading) {
    return (
      <div className="flex justify-center mt-10">
        <ImSpinner size={30} className=" animate-spin" />
      </div>
    );
  }

  return (
    <section
      className="overflow-auto h-screen"
      style={{ maxHeight: "calc(100vh - 50px)" }}
    >
      <div className="overflow-x-auto sm:rounded-lg mb-4">
        {quizzesData.length === 0 ? (
          <div className="text-center">
            <p>No available quizzes yet.</p>
          </div>
        ) : (
          <table className="w-full text-sm text-left">
            <thead className="border-b bg-gray-300">
              <tr>
                <th scope="col" className="px-6 py-3">
                  Student Name
                </th>
                <th scope="col" className="px-6 py-3">
                  Course
                </th>
                <th scope="col" className="px-6 py-3">
                  Chapter
                </th>
                <th scope="col" className="px-6 py-3">
                  Grade
                </th>
                <th scope="col" className="px-6 py-3">
                  Actions
                </th>
              </tr>
            </thead>
            <tbody>
              {quizzesData?.map((quiz, index) => (
                <tr key={index} className="bg-gray-200 border-b ">
                  <td className="px-6 py-4">{quiz.student_name}</td>
                  <td className="px-6 py-4">
                    {" "}
                    {quiz.course_name.toLowerCase().includes("ai")
                      ? `AI & Robotics ${quiz.course_name
                          .toLowerCase()
                          .replace("grade", "")} - Part I`
                      : `AI & Robotics ${quiz.course_name
                          .toLowerCase()
                          .replace("grade", "")} - Part II`}
                  </td>
                  <td className="px-6 py-4">{quiz.chapter_name}</td>
                  <td className="px-6 py-4">{quiz.score} / 100</td>
                  <td className="px-6 py-4">
                    <button
                      onClick={(e) =>
                        retakeQuiz(e, quiz.quiz_id, quiz.student_id)
                      }
                      className="text-white bg-blue-500 hover:bg-blue-600 duration-300 px-3 py-2 rounded-md"
                    >
                      Retake Quiz
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        )}
      </div>
    </section>
  );
}

export default StudentQuizzes;
