import { Link } from "react-router-dom";
import { BiUserCircle } from "react-icons/bi";
import { IoIosMenu } from "react-icons/io";
import { AsyncImage } from "loadable-image";
import { Blur } from "transitions-kit";
import Boxmind from "../../Assets/boxMind_wide.png";

function NavBar({ changeSideActive, userData }: any) {
  return (
    <nav className=" flex flex-row justify-between items-center bg-[#20144C] border-b border-[#C5D4EB] p-4 h-[71px]">
      <div className="h-[60px] ml-10">
        <div className=" flex items-center justify-center">
          <Link to={"/"}>
            <img
              src={Boxmind}
              alt="Boxmind"
              width={150}
              className="pt-2 -ml-5"
              loading="lazy"
            />
          </Link>
        </div>
      </div>
      <div className="flex flex-row gap-10 max-sm:gap-2 items-center">
        <div className="flex flex-row gap-2 items-center text-[white]">
          <Link to={"/profile"}>
            <div onClick={() => changeSideActive()}>
              {userData?.profile_picture ? (
                <AsyncImage
                  className="rounded-full w-[40px] h-[40px] object-cover"
                  src={`data:image/jpg;base64,${userData?.profile_picture}`}
                  alt={userData?.user_name}
                  style={{ width: 50, height: 50 }}
                  Transition={Blur}
                  loading="lazy"
                />
              ) : (
                <BiUserCircle className=" text-[35px] max-lg:text-[25px]" />
              )}
            </div>
          </Link>
          <div className=" font-semibold text-[16px] max-lg:hidden">
            {userData?.user_name}
          </div>
          <div className=" lg:hidden mt-1">
            <button onClick={() => changeSideActive()}>
              <IoIosMenu size={25} />
            </button>
          </div>
        </div>
      </div>
    </nav>
  );
}

export default NavBar;
