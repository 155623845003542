import { useState, useRef, useEffect } from "react";
import { CgProfile } from "react-icons/cg";
import { IoChevronBackSharp } from "react-icons/io5";
import { MdOutlineMailOutline } from "react-icons/md";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { MdDelete } from "react-icons/md";
import { TbLock } from "react-icons/tb";
import { TbLockCheck } from "react-icons/tb";
import { BiSolidShow, BiSolidHide } from "react-icons/bi";
import Cookies from "js-cookie";
import api from "../../../api";
import { AsyncImage } from "loadable-image";
import { Blur } from "transitions-kit";
import { MdPhoneInTalk } from "react-icons/md";
import { MdDateRange } from "react-icons/md";

const ProfileSettings = ({ userData, setRefresh }: any) => {
  const user_id = localStorage.getItem("user_id") || "";

  const [file, setFile] = useState<File | null>(null);
  const [name, setName] = useState(
    userData?.user_name || localStorage.getItem("user_name")
  );
  const [phoneNumber, setPhoneNumber] = useState(userData?.phone_number || "");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [oldPassword, setOldPassword] = useState("");
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [isVisible, setIsVisible] = useState<boolean>(false);
  const [firstName, setFirstName] = useState(userData.first_name);
  const [lastName, setLastName] = useState(userData.last_name);
  const formatDate = (dateString: string) => {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Ensure 2 digits
    const day = String(date.getDate()).padStart(2, "0"); // Ensure 2 digits
    return `${year}-${month}-${day}`;
  };

  const [dateOfBirth, setDateOfBirth] = useState<string>("");

  useEffect(() => {
    if (userData.date_of_birth) {
      setDateOfBirth(formatDate(userData.date_of_birth));
    }
  }, [userData]);

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const selectedFile = e.target.files && e.target.files[0];
    if (selectedFile) {
      setFile(selectedFile);
    }
  };

  const handleClearButtonClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.value = "";
      setFile(null);
    }
  };

  const handleUploadSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const formData = new FormData();

    formData.append("user_id", user_id);
    formData.append("username", name);
    formData.append("phone_number", phoneNumber);
    formData.append("old_password", oldPassword);
    formData.append("first_name", firstName);
    formData.append("last_name", lastName);
    if (dateOfBirth !== undefined) {
      formData.append("date_of_birth", dateOfBirth);
    }

    if (password === "") {
      formData.append("new_password", password);
    } else {
      if (password === confirmPassword) {
        formData.append("new_password", password);
      } else {
        return toast.error("Passwords doesn't match!", {
          position: "top-center",
          autoClose: 2000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          theme: "dark",
        });
      }
    }
    if (file === null) {
      formData.append("profile_picture", userData?.profile_picture);
    } else {
      formData.append("profile_picture", file);
    }

    try {
      const response = await api.post(
        `${process.env.REACT_APP_EDIT_PROFILE}`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      localStorage.setItem("user_name", name);
      response.data.access_token &&
        Cookies.set("access_token", response.data.access_token, {
          expires: 1,
          // secure: true,
          // sameSite: "Strict",
          // httpOnly: true,
        });

      setRefresh((prevRefresh: any) => prevRefresh + 1);
      toast.success("User has been Updated successfully!", {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        theme: "dark",
      });
    } catch (error) {
      if (error instanceof Error) {
        // You can check for specific error properties or just use a default message
        const errorMessage =
          (error as any)?.response?.data?.message ||
          error.message ||
          "An unexpected error occurred";

        toast.error(errorMessage, {
          position: "top-center",
          autoClose: 2000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          theme: "dark",
        });
      } else {
        // Fallback for when error is not an instance of Error
        toast.error("An unexpected error occurred", {
          position: "top-center",
          autoClose: 2000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          theme: "dark",
        });
      }

      console.log(error);
    }
  };

  const deleteProfilePicture = async () => {
    const formData = new FormData();

    formData.append("user_id", user_id);

    try {
      await api.post(
        `${process.env.REACT_APP_DELETE_PROFILE_PICTURE}`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      localStorage.setItem("user_name", name);
      setRefresh((prevRefresh: any) => prevRefresh + 1);
      toast.success("Profile picture has been deleted successfully!", {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        theme: "dark",
      });
    } catch (error) {
      toast.error("An error occurred while deleting the Profile picture", {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        theme: "dark",
      });
      console.log(error);
    }
  };

  const handleTogglePasswordVisibility = () => {
    setIsVisible(!isVisible);
  };

  return (
    <section>
      <div className="flex flex-row items-center w-[200px]  gap-1">
        <Link
          className="px-2 hover:-ml-1 hover:pr-3 duration-200 rosunded-lg "
          to={"/profile"}
        >
          <IoChevronBackSharp title="Profile" size={17} />
        </Link>
        <p className="font-bold text-[20px]">Account Details</p>
      </div>
      <div className="mt-2 flex items-center justify-center ">
        <div
          className=" bg-white p-4 w-[600px] max-lg:w-full shadow-lg rounded-lg overflow-scroll "
          style={{ maxHeight: "calc(100vh - 170px)" }}
        >
          <div className=" flex flex-col gap-4 justify-center items-center">
            {userData?.profile_picture ? (
              <>
                <AsyncImage
                  className="rounded-full w-[100px] h-[100px] object-cover"
                  src={`data:image/jpg;base64,${userData?.profile_picture}`}
                  alt={userData.user_name}
                  style={{ width: 80, height: 80, borderRadius: 3 }}
                  loading="lazy"
                  Transition={Blur}
                />
                <div className="flex justify-center">
                  <button
                    onClick={(e: any) => {
                      e.preventDefault();
                      deleteProfilePicture();
                    }}
                    className="bg-[#4D30B5] hover:bg-[#1b1436] text-white px-3 py-1 rounded duration-300"
                  >
                    Remove Profile Picture
                  </button>
                </div>
              </>
            ) : (
              <CgProfile size={50} />
            )}
            <form
              onSubmit={(e) => handleUploadSubmit(e)}
              className=" flex flex-col gap-2 w-full lg:px-16 overflow-scroll h-full"
            >
              <div className="grid grid-cols-2 gap-2">
                <div className=" flex flex-row gap-2 items-center border w-full px-2 rounded-lg">
                  <CgProfile size={22} className=" text-[#BBBCC0]" />
                  <input
                    required
                    type="text"
                    placeholder="First Name"
                    value={firstName}
                    onChange={(e) => setFirstName(e.target.value)}
                    className=" py-2 outline-none w-full"
                  />
                </div>
                <div className=" flex flex-row gap-2 items-center border w-full px-2 rounded-lg">
                  <CgProfile size={22} className=" text-[#BBBCC0]" />
                  <input
                    required
                    type="text"
                    placeholder="Last Name"
                    value={lastName}
                    onChange={(e) => setLastName(e.target.value)}
                    className=" py-2 outline-none w-full"
                  />
                </div>
              </div>
              <div className=" flex flex-row gap-2 items-center border w-full px-2 rounded-lg">
                <CgProfile size={22} className=" text-[#BBBCC0]" />
                <input
                  required
                  type="text"
                  placeholder="Name"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  className=" px-4 py-2 outline-none w-full"
                />
              </div>
              <div className=" flex flex-row gap-2 items-center border w-full px-2 rounded-lg">
                <MdDateRange size={22} className=" text-[#BBBCC0]" />
                <input
                  required
                  type="date"
                  placeholder="Date Of Birth"
                  value={dateOfBirth}
                  onChange={(e) => setDateOfBirth(e.target.value)}
                  className=" px-4 py-2 outline-none w-full"
                />
              </div>
              <div className=" flex flex-row gap-2 items-center border w-full px-2 rounded-lg">
                <MdOutlineMailOutline size={22} className=" text-[#BBBCC0]" />
                <input
                  disabled
                  type="email"
                  placeholder="Email"
                  value={userData?.user_email}
                  className=" px-4 py-2 outline-none w-full"
                />
              </div>
              <div className=" flex flex-row gap-2 items-center border w-full px-2 rounded-lg">
                <MdPhoneInTalk size={22} className=" text-[#BBBCC0]" />
                <input
                  type="text"
                  placeholder="Phone Number"
                  value={phoneNumber}
                  onChange={(e) => setPhoneNumber(e.target.value)}
                  className=" px-4 py-2 outline-none w-full"
                />
              </div>
              <div className=" flex flex-row gap-2 items-center border w-full px-2 rounded-lg">
                <CgProfile size={22} className=" text-[#BBBCC0]" />
                <input
                  type="file"
                  className=" px-4 py-2 outline-none w-full"
                  accept=".png, .jpg, .jpeg, .gif"
                  ref={fileInputRef}
                  onChange={handleFileChange}
                ></input>
                {file === null ? null : (
                  <div
                    className=" cursor-pointer p-1 bg-red-600 rounded-lg "
                    onClick={handleClearButtonClick}
                    role="button"
                  >
                    <MdDelete size={15} color="white" />
                  </div>
                )}
              </div>
              <div className=" relative flex flex-row gap-2 items-center border w-full px-2 rounded-lg">
                <TbLock size={22} className=" text-[#BBBCC0]" />
                <input
                  required={password !== "" || confirmPassword !== ""}
                  type={isVisible ? "text" : "password"}
                  placeholder="Old Password"
                  value={oldPassword}
                  onChange={(e) => setOldPassword(e.target.value)}
                  className=" px-4 py-2 outline-none w-full"
                />
                <button
                  type="button"
                  onClick={handleTogglePasswordVisibility}
                  className="outline-none text-[#083a5e] absolute right-2"
                >
                  {isVisible ? <BiSolidShow /> : <BiSolidHide />}
                </button>
              </div>
              <div className=" relative flex flex-row gap-2 items-center border w-full px-2 rounded-lg">
                <TbLock size={22} className=" text-[#BBBCC0]" />
                <input
                  required={oldPassword !== "" || confirmPassword !== ""}
                  type={isVisible ? "text" : "password"}
                  placeholder="New Password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  className=" px-4 py-2 outline-none w-full"
                />
                <button
                  type="button"
                  onClick={handleTogglePasswordVisibility}
                  className="outline-none text-[#083a5e] absolute right-2"
                >
                  {isVisible ? <BiSolidShow /> : <BiSolidHide />}
                </button>
              </div>
              <div className="relative flex flex-row gap-2 items-center border w-full px-2 rounded-lg">
                <TbLockCheck size={22} className=" text-[#BBBCC0]" />
                <input
                  required={oldPassword !== "" || password !== ""}
                  type={isVisible ? "text" : "password"}
                  placeholder="Confirm Password"
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                  className=" px-4 py-2 outline-none w-full"
                />
                <button
                  type="button"
                  onClick={handleTogglePasswordVisibility}
                  className="outline-none text-[#083a5e] absolute right-2"
                >
                  {isVisible ? <BiSolidShow /> : <BiSolidHide />}
                </button>
              </div>

              <div className="mt-4 flex justify-center">
                <button
                  type="submit"
                  className="bg-[#4D30B5] hover:bg-[#1b1436] text-white px-3 py-1 rounded duration-300"
                >
                  Save
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ProfileSettings;
